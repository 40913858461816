/* eslint jsx-a11y/no-autofocus: 0 */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox } from 'react-instantsearch-dom'

import SeachHits from 'src/components/searchHits'
import { graphql } from 'gatsby'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const SearchPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Поиск" />
      <InstantSearch searchClient={searchClient} indexName="Posts">
        <div
          sx={{
            variant: 'boxes.container',
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            mt: 5,
            px: [4, 4, 9],
            '.ais-SearchBox': {
              width: '100%',
            },
            '.ais-SearchBox-input': {
              display: 'block',
              width: '100%',
              mx: 'auto',
              px: 3,
              py: 2,
              border: '2px solid',
              borderColor: 'primary',
              borderRadius: '4px',
              bg: '#F8F9F9',
              boxShadow: 'none',
              outline: 0,
              fontSize: 1,
              color: '#151B26',
            },
            '.ais-SearchBox-submit': {
              display: 'none',
            },
            '.ais-SearchBox-reset': {
              display: 'none',
            },
          }}
        >
          <h3 sx={{ fontSize: [3, 3, 4] }}>Поиск</h3>
          <SearchBox
            showLoadingIndicator={false}
            translations={{
              placeholder: 'Введите ключевое слово или фразу...',
            }}
            autoFocus
          />
          <SeachHits data={data} />
        </div>
      </InstantSearch>
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query {
    allWordpressPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        wordpress_id
        slug
        title
        excerpt
        date
        categories {
          wordpress_id
          name
          slug
          description
        }
        fields {
          readingTime {
            minutes
          }
          viewCount
        }
        featured_media {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 680, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
