/** @jsx jsx */
import { useContext } from 'react'
import { jsx } from 'theme-ui'
import { connectHits } from 'react-instantsearch-dom'

import { PostsViewsContext } from 'src/context/postsViewsContext'
import Card from 'src/components/card'

const SearchHits = ({
  data: {
    allWordpressPost: { nodes: allPosts },
    placeholder,
  },
  hits,
}) => {
  const { getPostViews } = useContext(PostsViewsContext)

  const hitsPosts = allPosts
    .filter((post) => hits.find((hit) => hit.slug === post.slug))
    .slice(0, 9)

  return (
    <div
      sx={{
        mt: 8,
        bg: 'background',
      }}
    >
      {hitsPosts.length > 0 ? (
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: [
              '1fr',
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
            ],
            gridAutoRows: '460px',
            gap: 5,
            mb: 9,
          }}
        >
          {hitsPosts.map((post, i) => (
            <div key={post.id}>
              <Card
                isBig={i === 0}
                slug={post.slug}
                title={post.title}
                tags={post.categories}
                date={post.date}
                image={post.featured_media?.localFile ?? placeholder}
                timeToRead={post.fields.readingTime.minutes}
                views={getPostViews(post.wordpress_id) ?? post.fields.viewCount}
                excerpt={post.excerpt}
              />
            </div>
          ))}
        </div>
      ) : (
        <p>
          К сожалению по вашему запросу ничего не найдено{' '}
          <span role="img" aria-label="нам очень жаль">
            😅
          </span>
        </p>
      )}
    </div>
  )
}

export default connectHits(SearchHits)
